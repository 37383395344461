import { DATA_CONSTANT } from '../constants/DataConstant';
import { JARGON_CONSTANT } from "../constants/JargonConstant";
import { each } from 'underscore';
import { SessionStorageService } from '../services/SessionStorageService';

export const CommonService = {
    getFileExtension: (fileName) => {
        let extension = "";
        if (fileName) {
            extension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
        }
        return extension;
    },

    isValidBOMFile: (fileName) => {
        let fileExtension = "";
        let validExtension = DATA_CONSTANT.validExtensionForBomFile;
        if (fileName) {
            fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
        }
        if (validExtension.indexOf(fileExtension) > -1) {
            return true;
        }
        return false;
    },

    createBomFileFromBlobUrl: async (url, fileName) => {
        const fetchResult = await fetch(url);
        const result = await fetchResult.blob();

        if (fetchResult.ok) {
            let types = DATA_CONSTANT.bomFileType
            let extension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
            let metadata = {
                type: types.find((x) => x.extension === extension).type
            };

            let file = new File([result], fileName, metadata);
            return file;
        }

        const responseError = {
            type: 'Error',
            message: result.message || 'Something went wrong',
            data: result.data || '',
            code: result.code || '',
        };

        const error = new Error();
        error.info = responseError;

        return (error);
    },

    deepShallowCopy: (data) => {
        return (JSON.parse(JSON.stringify(data)));
    },

    convertChecklist: (rfqDetail, checkListBuilderData) => {
        let checkListDetail = CommonService.deepShallowCopy(checkListBuilderData);

        let objCheckListBuilder = {
            RFQId: rfqDetail.quoteId,
            AssemblyId: rfqDetail.assemblyId,
            RFQCustomFieldValues: [],
            CustomQuoteFieldNames: []
        };

        let isAnyFieldRequiresCustomQuote = false;
        if (checkListDetail.length > 0) {
            each(checkListDetail, function (value) {
                if ((value.fieldType?.toLowerCase() === "checkbox" || value.fieldType?.toLowerCase() === "dropdown" || value.fieldType?.toLowerCase() === "radiobutton") && value.defaultValue !== null) {
                    if (Array.isArray(value.defaultValue)) {
                        value.defaultValue = value.defaultValue.join(",");
                    }
                } else if (value.defaultValue == null) {
                    value.defaultValue = "";
                }
                var objValues = {
                    Id: value.id,
                    FieldTypesName: value.name,
                    FieldTypeValue: value.defaultValue
                };
                objCheckListBuilder.RFQCustomFieldValues.push(objValues);
                if (value.isCustomQuote) {
                    objCheckListBuilder.CustomQuoteFieldNames.push(value.name);
                    isAnyFieldRequiresCustomQuote = true;
                    return;
                }
            });
        }
        objCheckListBuilder.IsAnyFieldRequiresCustomQuote = isAnyFieldRequiresCustomQuote;
        return objCheckListBuilder;
    },

    generateRandomId: (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },

    getJargonValue: (key) => {
        return JARGON_CONSTANT.jargonValues.find(x => x.label == key)?.displayValue;
    },

    getJargonDetail: (key) => {
        return JARGON_CONSTANT.jargonValues.find(x => x.label == key);
    },

    getCookie: (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    calculateTax: (configuredTax, paymentDetail) => {
        if (!configuredTax || !paymentDetail) {
            return 0;
        }

        if (configuredTax.includes('%')) {

            let totalPrice = paymentDetail.subTotalPrice + paymentDetail.shippingCharge - paymentDetail.discount;
            let taxPercentage = Number(configuredTax.split('%')[0]) * 1;
            let calculatedTax = (totalPrice / 100) * taxPercentage;
            return parseFloat(calculatedTax.toFixed(2));

        } else {
            return parseFloat((+configuredTax).toFixed(2));
        }
    },

    calculateTotal: (paymentDetail) => {
        if (!paymentDetail) {
            return 0;
        }

        let total = ((paymentDetail.subTotalPrice + paymentDetail.tax + paymentDetail.shippingCharge) - paymentDetail.discount);

        return parseFloat(total.toFixed(2));
    },

    getRoutePath: (pathName) => {
        if (DATA_CONSTANT.IsCustomDomainClient) {
            return `/${pathName}`;
        }
        return `/${DATA_CONSTANT.ClientCode}/${pathName}`;
    },
    getQueryParam: (name) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        return params.get(name);
    },
    getUserEmail: () => {

        let loggedInUserEmail = SessionStorageService.getSessionValue('LoggedInUserEmail');

        if (loggedInUserEmail && loggedInUserEmail != "undefined") {
            return loggedInUserEmail;
        }

        let storeJson = JSON.parse(SessionStorageService.getSessionValue('persist:root'));
        let salesPersonEmail = storeJson ? (JSON.parse(storeJson.DefaultConfigs)?.defaultConfigs?.salesPersonEmail) : "";
        let userEmail = salesPersonEmail ? salesPersonEmail : "";
        return userEmail;

    },
    roundToTwoDecimal: (num) => {
        return Math.round(num * 100) / 100;
    }
}